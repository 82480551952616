import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { displayName } from "react-currency-formatter";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

export default function Header(props) {
  const { handleShowData } = props;
  return (
    <Nav variant="tabs">
      <Nav.Item>
        <LinkContainer to="/" exact>
          <Nav.Link eventKey="link-1" onClick={() => handleShowData(false)}>
            ALL STYLES
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/KW-Web-Product" exact>
          <Nav.Link eventKey="link-2" onClick={() => handleShowData(false)}>
            KW WEB PRODUCT
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/FL-Web-Product" exact>
          <Nav.Link eventKey="link-3" onClick={() => handleShowData(false)}>
            FL WEB PRODUCT
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/KW-Web-Variations" exact>
          <Nav.Link eventKey="link-4" onClick={() => handleShowData(false)}>
            KW WEB VARIATIONS
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/FL-Web-Variations" exact>
          <Nav.Link eventKey="link-5" onClick={() => handleShowData(false)}>
            FL WEB VARIATIONS
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/All-In-Stock-Serials" exact>
          <Nav.Link eventKey="link-6" onClick={() => handleShowData(false)}>
            ALL IN STOCK SERIALS
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </Nav>
  );
}
