import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LoadingOverlay from "react-loading-overlay";
import download from "downloadjs";
import ImageGallery from "react-image-gallery";
import "../../styles/Main.scss";

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.gallaryRef = React.createRef();
    this.state = {
      showModal: this.props.show || false,
      isLoading: true,
    };
    this.onModalHide = this.onModalHide.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.download = this.download.bind(this);
  }
  async download() {
    const gallaryImageIndex = this.gallaryRef.current.getCurrentIndex();
    var img = this.props.image[gallaryImageIndex].original;
    var name = this.props.image[gallaryImageIndex].imgName;
    var x = new XMLHttpRequest();
    x.open("GET", img, true);
    x.responseType = "blob";
    x.onload = function(e) {
      download(e.target.response, name, "image/png");
    };
    x.send();
  }
  onLoad() {
    this.setState({
      isLoading: false,
    });
  }
  onModalHide() {
    let { callback } = this.props;
    this.setState(
      {
        showModal: false,
      },
      () => {
        callback && callback();
      }
    );
  }

  render() {
    let { showModal, isLoading } = this.state;
    let { image } = this.props;

    return (
      <div className="modal-container">
        <Modal
          animation={false}
          autoFocus={false}
          enforceFocus={false}
          className="image-modal"
          centered="true"
          // size="sm"
          show={showModal}
          onHide={() => this.onModalHide()}
        >
          <Modal.Header closeButton className="modal-header-con">
            <button onClick={() => this.download()} className="download--btn">
              Download
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="image-modal-con">
              <LoadingOverlay
                active={isLoading}
                spinner
                text="Loading image..."
              >
                <ImageGallery
                  items={image}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  onImageLoad={this.onLoad}
                  ref={this.gallaryRef}
                />
              </LoadingOverlay>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ImageModal;
