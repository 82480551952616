import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function WRSettingType() {
  return (
    <MultiDropdownList
      title="WR Setting Type"
      className="form-field"
      componentId="WRSettingType"
      dataField="SettingType.keyword"
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
