import React from "react";
import { SingleDataList } from "@appbaseio/reactivesearch";

export default function SelectBrand() {
  return (
    <SingleDataList
      className="Select-Brand"
      componentId="Brand"
      dataField="Brand.keyword"
      title="Brand"
      data={[
        {
          label: "Kwiat",
          value: "Kwiat",
        },
        {
          label: "Fred Leighton",
          value: "Fred Leighton",
        },
      ]}
      showSearch={false}
      showRadio={true}
    />
  );
}
