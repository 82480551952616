import React, { Component } from "react";
import { SingleList } from "@appbaseio/reactivesearch";

export default function ShopableCenter() {
  return (
    <div className="is-kwiat">
      <SingleList
        className="toggle__filters"
        componentId="Shopable Center"
        dataField="ShoppableSettings.keyword"
        showSearch={false}
        sortBy="asc"
        render={({ data, value, handleChange }) => {
          var checkedFlag;
          if (value === "1") {
            checkedFlag = true;
          } else {
            checkedFlag = false;
          }
          if (data.length === 1) {
            return (
              <ul>
                <li></li>
                <li>
                  <input
                    type="checkbox"
                    value={1}
                    onChange={handleChange}
                    checked={checkedFlag}
                  />
                  <span className="toggle--label">Shopable Center</span>
                </li>
              </ul>
            );
          } else {
            return (
              <ul>
                {data.map((item) => (
                  <li key={item.key}>
                    <input
                      type="checkbox"
                      value={item.key}
                      onChange={handleChange}
                      checked={checkedFlag}
                    />
                    <span className="toggle--label">Shopable Center</span>
                  </li>
                ))}
              </ul>
            );
          }
        }}
      />
    </div>
  );
}
