import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function ERShape() {
  return (
    // // <div className="row p-0 pt-2">
    //   {/* <div className="col-sm-auto p-0">ER Shape</div> */}
    //   {/* <div className="col-sm-auto"> */}

    <MultiDropdownList
      className="form-field"
      title="ER Shape"
      componentId="ER Shape"
      dataField={"CenterShape.keyword"}
      size={100}
      showCount={false}
      showSearch={true}
      placeholder="Select values"
    />

    //   {/* </div> */}
    // // </div>
  );
}
