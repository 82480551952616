import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import { values } from "lodash";

export default function Tags() {
  return (
    <MultiDropdownList
      title="Tags"
      className="form-field"
      componentId="Tags"
      dataField={"TagAttributeDescr.keyword"}
      size={100}
      transformData={(data) => {
        // console.log("tags data: ", data);
        let tagsData = [
          ...new Set(
            data
              .map((tag) => tag.key)
              .map((tag) => {
                if (tag.includes(",")) {
                  return tag.split(",");
                }
              })
              .filter((tag) => tag !== undefined)
              .flat(Infinity)
          ),
        ].map((tag) => {
          return { key: tag };
        });

        // console.log("tagsData: ", tagsData);
        return tagsData;
      }}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
      // customQuery={(value) => {
      //   // console.log("tag value: ", value);

      //   if (value && value !== undefined) {
      //     value = value.toString();
      //     // return {
      //     //   query: {
      //     //     bool: {
      //     //       should: [
      //     //         {
      //     //           terms: {
      //     //             "TagAttributeDescr.keyword": value,
      //     //           },
      //     //           minimum_should_match_field: value.length,
      //     //         },
      //     //       ],
      //     //     },
      //     //   },
      //     // };
      //     // return {
      //     //   query: {
      //     //     boolen: {
      //     //       should: [
      //     //         {
      //     //           match_phrase: {
      //     //             "TagAttributeDescr.keyword": value,
      //     //           },
      //     //         },
      //     //       ],
      //     //     },
      //     //   },
      //     // };
      //   }
      //   return {
      //     query: {
      //       bool: {
      //         should: [
      //           {
      //             term: {
      //               "TagAttributeDescr.keyword": value,
      //             },
      //           },
      //         ],
      //       },
      //     },
      //   };
      // }}
    />
  );
}
