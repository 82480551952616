import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function WebProductGro() {
  return (
    <MultiDropdownList
      title="Web Product Group"
      className="form-field"
      componentId="Web Product Gro"
      dataField={"ProductGroupDescr.keyword"}
      size={100}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
