import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function InvCategory() {
  return (
    <MultiDropdownList
      title="Inv Category"
      className="form-field"
      componentId="Inventory Category"
      dataField={"InventoryCategory.keyword"}
      size={100}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
