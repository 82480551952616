import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function BridalParentGroup() {
  return (
    // <div className="row p-0 pt-2">
    //   <div className="col-sm-auto p-0">Collection</div>
    //   <div className="col-sm-auto">
    <MultiDropdownList
      className="form-field"
      title="Bridal Parent Group"
      componentId="BridalParentGroup"
      dataField={"BridalParentGroup.keyword"}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      size={1000}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
    //   </div>
    // </div>
  );
}
