export const WebProductGroup = [
  { label: "", value: "" },
  { label: "Essentials>Round Brilliant Diamond Studs", value: "STUDS" },
  {
    label: "Essentials>Round Brilliant Diamond Solitaire Pendants",
    value: "SOLPEND",
  },
  { label: "White Gold Initial Pendant", value: "INITIALWG" },
  { label: "Yellow Gold Initial Pendant", value: "INITIALYG" },
  { label: "Rose Gold Initial Pendant", value: "INITIALRG" },
  { label: "Initial Pendants", value: "INITIALPEN" },
  {
    label: "Shared Prong Round Diamond Hoops 18KW",
    value: "SHRPRNBRWG",
  },
  {
    label: "Oval Shaped Shared Prong Round Diamond Hoops 18KW",
    value: "OVSHRPRBRW",
  },
  {
    label: "Shared Prong Round Diamond Hoops 18KY",
    value: "SHRPRNBRYG",
  },
  { label: "Shared Prong Round Diamond Hoops", value: "SHRPRNBR" },
];
