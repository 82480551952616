import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function WRStoneShape(props) {
  const { data } = props;
  return (
    <MultiDropdownList
      title="WR Stone Shape"
      className="form-field"
      componentId="WRStoneShape"
      dataField={data}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
