import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function StyleStatus() {
  return (
    <MultiDropdownList
      className="form-field"
      title="Style Status"
      componentId="StyleStatus"
      dataField={"StyleStatus.keyword"}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
