import React from "react";
const ProductLoader = () => {
  return (
    <div className="es-product-loader">
      <i
        className="fa fa-spinner fa-2x fa-spin"
        style={{ textShadow: "0 1px 0 rgba(0, 0, 0, 0.1)" }}
      />
    </div>
  );
};
export default ProductLoader;
