import React from "react";
import { DataSearch } from "@appbaseio/reactivesearch";
import { KeywordsSearch } from "../../utils/constants";
import Metal from "./Metal";

export default function Keyword(props) {
  const { data, handleShowData } = props;
  return (
    <DataSearch
      className="form-field"
      componentId="Search Keyword"
      title="Keywords"
      debounce={250}
      placeholder="Start Search..."
      dataField={KeywordsSearch}
      autosuggest={false}
      queryFormat="and"
      searchOperators={true}
      iconPosition="right"
      onKeyDown={(e) => {
        e.stopPropagation();
        // e.preventDefault();
        // console.log("e : ", e);
        // console.log("e.target : ", e.target.value);
        if (e.key === "Enter") {
          handleShowData(true);
        }
      }}
    />
  );
}
