import React from "react";
import { DataSearch } from "@appbaseio/reactivesearch";

export default function InventoryID(props) {
  const { data } = props;
  return (
    // <div className="row p-0">
    //   <div className="col-sm-auto p-0">Inventory ID</div>
    //   <div className="col-sm-auto ">
    <DataSearch
      title="Inventory ID"
      className="form-field"
      placeholder="Enter Style"
      dataField={data}
      autosuggest={true}
      queryFormat="and"
      componentId="InventoryID"
      iconPosition="right"
    />
    //   </div>
    // </div>
  );
}
