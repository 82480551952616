import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function ERSetting() {
  return (
    <MultiDropdownList
      title="ER Setting"
      className="form-field"
      componentId="ER Setting"
      dataField="CenterSettingType.keyword"
      transformData={(data) => {
        // console.log("settings data: ", data);
        let settingsData = [
          ...new Set(
            data
              .map((setting) => setting.key)
              .map((setting) => {
                if (setting.includes(",")) {
                  return setting.split(",");
                }
              })
              .filter((setting) => setting !== undefined)
              .flat(Infinity)
          ),
        ].map((setting) => {
          return { key: setting };
        });

        // console.log("settingsData: ", settingsData);
        return settingsData;
      }}
      renderListItem={(label) => <li>{label !== "NULL" ? label : "None"}</li>}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
      // customQuery={(value) => {
      //   // console.log("tag value: ", value);

      //   if (value && value !== undefined) {
      //     value = value.toString();
      //   }
      //   return {
      //     query: {
      //       bool: {
      //         should: [
      //           {
      //             term: {
      //               "CenterSettingType.keyword": value,
      //             },
      //           },
      //         ],
      //       },
      //     },
      //   };
      // }}
    />
  );
}
