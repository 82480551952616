export const Tags = [
  // { value: "", label: "" },
  { value: "ASHKA", label: "Ashoka" },
  { value: "EXCBRIL", label: "Exceptional Brilliance" },
  { value: "FEAT", label: "Featured" },
  { value: "GIFT", label: "Gift" },
  { value: "HOL18", label: "Holiday 2018" },
  { value: "HOLGG", label: "Holiday Gift Guide" },
  { value: "ESSEN", label: "Kwiat Essentials" },
  { value: "MOON", label: "Moonlight" },
  { value: "NATCLRJEWL", label: "Natural Fancy Color Diamond Jewelry" },
  { value: "NATCLRRIN", label: "Natural Fancy Color Diamond Rings" },
  { value: "NATFYDJ", label: "Natural Fancy Yellow Diamond Jewelry" },
  { value: "NEW", label: "New" },
  { value: "SHINE", label: "Shine On" },
  { value: "STYLEBK16", label: "Style Book 2016" },
  { value: "DIABK16", label: "The Diamond Book 2016" },
  { value: "TIARACLUB", label: "Tiara Club" },
  { value: "WEDDING", label: "Wedding Day Jewelry" },
  { value: "KWSET", label: "Kwiat Setting" },
  { value: "NATFYDR", label: "Natural Fancy Yellow Diamond Rings" },
  { value: "EXQRINGS", label: "Exquisite Rings" },
  { value: "SPRINGCOLL", label: "Spring Collection" },
  { value: "FLASH10", label: "Flash Sale>10" },
  { value: "FLASH15", label: "Flash Sale>15" },
  { value: "FLASH20", label: "Flash Sale>20" },
  { value: "FLASH25", label: "Flash Sale>25" },
  { value: "FLASH30", label: "Flash Sale>30" },
  { value: "FLASH35", label: "Flash Sale>35" },
  { value: "FLASH40", label: "Flash Sale>40" },
  { value: "FLASH45", label: "Flash Sale>45" },
  { value: "FLASH50", label: "Flash Sale>50" },
  { value: "FLASH55", label: "Flash Sale>55" },
  { value: "FLASH60", label: "Flash Sale>60" },
  { value: "FLASH65", label: "Flash Sale>65" },
  { value: "FLASH70", label: "Flash Sale>70" },
  { value: "MENS", label: "Mens Gifts" },
  { value: "FIRSTLOOK", label: "First Look" },
  { value: "BESTSELLER", label: "Best Seller" },
  { value: "FESTIVE", label: "Festive" },
  { value: "QUICKSHIP", label: "Quick Delivery" },
  { value: "NEW20", label: "New Styles" },
  { value: "FEATURE20", label: "Featured New" },
  { value: "BUYHOLD", label: "Buy and Hold" },
  { value: "VDAY", label: "Valentines Day" },
  { value: "FLORALMOM", label: "Floral Mom" },
  { value: "VINTAGEMOM", label: "Vintage Mom" },
  { value: "MINIMALMOM", label: "Minimalist Mom" },
  { value: "COLHERMOM", label: "Color Her World Mom" },
  { value: "MOTHERSDAY", label: "Mothers Day" },
];
