import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function ERPartway() {
  return (
    // // <div className="row p-0 pt-2">
    //   {/* <div className="col-sm-auto p-0">ER Partway/Eter</div> */}
    //   {/* <div className="col-sm-auto"> */}
    <MultiDropdownList
      title="ER Partway/Eter"
      className="form-field"
      componentId="ERPartway/Eter"
      dataField="PartwayEternity.keyword"
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
    //   {/* </div> */}
    // // </div>
  );
}
