import React from "react";
import { Container } from "react-bootstrap";

export default function All_InStockSerials() {
  return (
    <Container className="All-In-Stock-Serials" fluid>
      <h1>All In Stock Serials</h1>
    </Container>
  );
}
