import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function SubCollections() {
  return (
    // <div className="row p-0 pt-2">
    //   <div className="col-sm-auto p-0">Sub Collection</div>
    //   <div className="col-sm-auto">
    <MultiDropdownList
      className="form-field"
      title="Sub Collection"
      componentId="SubCollection"
      dataField={"SubCollection.keyword"}
      react={{ and: ["Collection"] }}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
    />
    //   </div>
    // </div>
  );
}
