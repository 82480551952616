import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function Period() {
  return (
    // <div className="row p-0 pt-2">
    //   <div className="col-sm-auto p-0">Period</div>
    //   <div className="col-sm-auto">
    <MultiDropdownList
      title="Period"
      className="form-field"
      componentId="Period"
      dataField={"Period.keyword"}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      placeholder="Select values"
    />
    //   </div>
    // </div>
  );
}
