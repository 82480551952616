import { React, useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import {
  ReactiveBase,
  ReactiveList,
  SelectedFilters,
} from "@appbaseio/reactivesearch";
import Appbase from "appbase-js";
import ItemType from "../Search Components/ItemType";
import {
  KwiatWebProductApp,
  FredWebProductApp,
  StyleGroupAttributesApp,
  AppbaseAppUrl,
  JewelrySerialApp,
  JewelryStyleApp,
  AppbaseCredentials,
  SerialDataField,
  StyleDataField,
  KeywordsSearch,
  WRShapeSerial,
  WRShapeStyle,
} from "../../utils/constants";
import InventoryID from "../Search Components/InventoryID";
import SelectBrand from "../Search Components/SelectBrand";
import ItemSubType from "../Search Components/ItemSubType";
import Collection from "../Search Components/Collection";
import SubCollections from "../Search Components/SubCollections";
import Maker from "../Search Components/Maker";
import Period from "../Search Components/Period";
import ERShape from "../Search Components/ERShape";
import ERSetting from "../Search Components/ERSetting";
import ERGemstone from "../Search Components/ERGemstone";
import ERPartway from "../Search Components/ERPartway";
import WRStoneShape from "../Search Components/WRStoneShape";
import WRSettingType from "../Search Components/WRSettingType";
import Price from "../Search Components/Price";
import Metal from "../Search Components/Metal";
import Tags from "../Search Components/Tags";
import PriceFilter from "../Search Components/PriceFilter";
import StockLevels from "../Search Components/StockLevels";
import Availability from "../Search Components/Availability";
import WebProductGro from "../Search Components/WebProductGro";
import StyleStatus from "../Search Components/StyleStatus";
import BridalParentGroup from "../Search Components/BridalParentGroup";
import ItemClass from "../Search Components/ItemClass";
import InvCategory from "../Search Components/InvCategory";
import Keyword from "../Search Components/Keyword";
import ParentStyleNumber from "../Search Components/ParentStyleNumber";
import ShowOnKwiat from "../Search Components/ShowOnKwiat";
import ShowOnFL from "../Search Components/ShowOnFL";
import HasWebImage from "../Search Components/HasWebImage";
import WebDataEntered from "../Search Components/WebDataEntered";
import SerialAsVariations from "../Search Components/SerialAsVariations";
import SerialAsIndividual from "../Search Components/SerialAsIndividual";
import ShopableCenter from "../Search Components/ShopableCenter";
import MasterParentStyle from "../Search Components/MasterParentStyle";
import Results from "./Results";
import ProductLoader from "../Search Components/ProductLoader";
import LoadingOverlay from "react-loading-overlay";

export default function AllStyle(props) {
  const {
    appName,
    appUrl,
    appCredentials,
    showData,
    handleShowData,
    toggleLoader,
  } = props;
  // console.log("showData: ", showData);

  const [state, setState] = useState({
    showCustomLoader: false,
    nextQuery: {},
    dataStyleNumberList: [],
    dataStyleGroup: [],
  });

  const handleQueryChange = (prevQuery, nextQuery) => {
    if (prevQuery) {
      setState({
        ...state,
        showCustomLoader: true,
      });
      handleShowData(false);
    }
    setTimeout(() => {
      setState({
        ...state,
        showCustomLoader: false,
        nextQuery,
      });
      handleShowData(true);
    }, 1200);
  };

  const ShowLoader = (value) => {
    if (value) {
      setState({ ...state, showCustomLoader: true });
      handleShowData(false);
    } else {
      setState({ ...state, showCustomLoader: false });
      handleShowData(true);
    }
  };

  const getMasterParentSyle = async () => {
    let response;
    const appbaseRef = Appbase({
      app: appName,
      url: appUrl,
      credentials: appCredentials,
    });

    await appbaseRef
      .search({
        body: {
          query: {
            match: {
              MasterParentStyle: {
                query: "1",
              },
            },
          },
          _source: ["StyleNumber"],
          size: 10000,
        },
      })
      .then(function(res) {
        // console.log("query result: ", res.hits.hits);
        // let data = res.hits.hits.map((res) => res._source.StyleNumber);
        response = res.hits.hits.map((res) => res._source.StyleNumber);
        // console.log("msp data: ", res);
        // return data;
        // console.log("No of products: ", data.length);
        // setState({
        //   ...state,
        //   dataStyleNumberList: data.map((d) => ({ label: d, value: d })),
        // });
      })
      .catch(function(err) {
        // console.log("search error: ", err);
      });
    return response;
  };

  const getStyleGroupAttributes = async () => {
    let response;
    const appbaseRef = Appbase({
      app: StyleGroupAttributesApp,
      url: appUrl,
      credentials: appCredentials,
    });

    await appbaseRef
      .search({
        body: {
          query: {
            match_all: {},
          },
          _source: ["ValueID"],
          size: 10000,
        },
      })
      .then(function(res) {
        // console.log("query result: ", res.hits.hits);
        // let attrData = res.hits.hits.map((res) => res._source.ValueID);
        response = res.hits.hits.map((res) => res._source.ValueID);

        // console.log("data: ", res);
        // return attrData;
        // console.log("No of products: ", data.length);
        // setState({
        //   ...state,
        //   dataStyleGroup: attrData,
        // });
      })
      .catch(function(err) {
        // console.log("search error: ", err);
      });
    return response;
  };

  useEffect(() => {
    const SetData = async () => {
      let styleData = await getStyleGroupAttributes();
      let styleNumberData = await getMasterParentSyle();
      // console.log("styleNumberData :", styleNumberData);
      setState({
        ...state,
        dataStyleNumberList: styleNumberData && styleNumberData.map((d) => ({
          label: d,
          value: d,
        })),
        dataStyleGroup: ["", ...styleData],
      });
    };
    SetData();
  }, []);
  // console.log("State:", state);
  return (
    <ReactiveBase app={appName} url={appUrl} credentials={appCredentials}>
      <Container className="All-Style" fluid>
        <Row className="Fields-Container">
          <Col className="Search-fields-Col">
            <Row className="">
              <SelectBrand />
            </Row>
            <Row className="">
              <InventoryID data={StyleDataField} />
            </Row>
            <Row className="">
              <ItemType />
            </Row>
            <Row className="">
              <ItemSubType />
            </Row>
            <Row className="">
              <Collection />
            </Row>
            <Row className="">
              <SubCollections />
            </Row>
            <Row className="">
              <Maker />
            </Row>
            <Row className="">
              <Period />
            </Row>
          </Col>
          <Col className="Search-fields-Col">
            <Row className="">
              <ERShape />
            </Row>
            <Row className="">
              <ERSetting />
            </Row>
            <Row className="">
              <ERGemstone />
            </Row>
            <Row className="">
              <ERPartway />
            </Row>
            <Row className="">
              <WRStoneShape data={WRShapeStyle} />
            </Row>
            <Row className="">
              <WRSettingType />
            </Row>
            <Row className="">
              <Price />
            </Row>
          </Col>
          <Col className="Search-fields-Col">
            <Row className="">
              <Metal />
            </Row>
            <Row className="">
              <WebProductGro />
            </Row>
            <Row className="">
              <Tags />
            </Row>
            {appName === FredWebProductApp || appName === KwiatWebProductApp ? (
              <Row className="">
                <PriceFilter />
              </Row>
            ) : (
              ""
            )}
            <Row className="">
              <StockLevels />
            </Row>
            {appName === FredWebProductApp || appName === KwiatWebProductApp ? (
              <Row className="">
                <Availability />
              </Row>
            ) : (
              ""
            )}
          </Col>
          <Col className="Search-fields-Col">
            <Row className="">
              <StyleStatus />
            </Row>
            <Row className="">
              <BridalParentGroup />
            </Row>
            <Row className="">
              <ItemClass />
            </Row>
            <Row className="">
              <InvCategory />
            </Row>
            <Row className="">
              <Keyword data={KeywordsSearch} handleShowData={handleShowData} />
            </Row>
            <Row className="">
              <ParentStyleNumber />
            </Row>
            <Row className="">
              <Col className="checkbox-Col">
                <ShowOnKwiat />
                <HasWebImage />
                <SerialAsVariations />
                <ShopableCenter />
              </Col>
              <Col className="checkbox-Col">
                <ShowOnFL />
                <WebDataEntered />
                <SerialAsIndividual />
                <MasterParentStyle />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Button
            variant="secondary"
            // onClick={() => {
            //   setState({ ...state, ShowFilteredData: true });
            //   // getMasterParentSyle();
            // }}
            onClick={() => handleShowData(true)}
          >
            Search
          </Button>
        </Row>
      </Container>
      <SelectedFilters
        className="mt-2"
        onClear={() => setState({ ...state, ShowFilteredData: false })}
      />
      {state.showCustomLoader && <ProductLoader />}
      {showData && (
        <ReactiveList
          componentId="results"
          dataField={"RetailPrice"}
          // size={15}
          sortBy="asc"
          loader={<ProductLoader />}
          react={{
            and: [
              "Brand",
              "InventoryID",
              "ItemType",
              "Item-Subtype",
              "Collection",
              "SubCollection",
              "Maker",
              "Period",
              "ER Shape",
              "ER Setting",
              "ER Gemstone",
              "ERPartway/Eter",
              "WRStoneShape",
              "WRSettingType",
              "RetailPriceRange",
              "Metal",
              "Web Product Gro",
              "Tags",
              "PriceFilter",
              "stockLevels",
              "Availability",
              "StyleStatus",
              "BridalParentGroup",
              "Item Class",
              "Inventory Category",
              "Search Keyword",
              "ParentStyleNbr",
              "ShowOnKwiat.com",
              "HasWebImage",
              "WebDataEntered",
              "SerialAsIndividual",
              "SerialAsVariations",
              "Shopable Center",
              "MasterParentStyle",
              "ShowOnFL",
            ],
          }}
          renderResultStats={({ numberOfResults, time }) => {
            return numberOfResults + " results found in " + time + "ms";
          }}
          render={({ data, rawData }) => (
            <Results
              rawData={rawData}
              data={data}
              ShowLoader={ShowLoader}
              styleNumberList={state.dataStyleNumberList}
              attributes={state.dataStyleGroup}
              appName={appName}
              appUrl={appUrl}
              appCredentials={appCredentials}
              toggleLoader={toggleLoader}
            />
          )}
          onQueryChange={(prevQuery, nextQuery) =>
            handleQueryChange(prevQuery, nextQuery)
          }
          // onQueryChange={(prevQuery, nextQuery) =>
          //   this.handleQueryChange(prevQuery, nextQuery)
          // }
          // defaultQuery={() => this.defaultQuery(activeTabKey)}
          // scrollTarget={isMobile || isIPad13 ? "btn-target" : ""}
        />
      )}
    </ReactiveBase>
  );
}
