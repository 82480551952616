import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function Metal() {
  return (
    <MultiDropdownList
      className="form-field"
      title="Metal"
      componentId="Metal"
      dataField={"Metal.keyword"}
      size={100}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
