import React, { Component } from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import MultiDropdownListWithFocus from "../HOC/MultiDropdownListWithFocus";

class ItemType extends Component {
  render() {
    return (
      // <div className="row p-0 pt-2">
      //   <div className="col-sm-auto p-0">Item Type</div>
      //   <div className="col-sm-auto">
      <MultiDropdownList
        className="form-field"
        title="Item Type"
        componentId="ItemType"
        dataField={"ItemType.keyword"}
        size={100}
        showCount={false}
        showSearch={true}
        sortBy="asc"
        renderListItem={(label) => (
          <div>{label !== "NULL" ? label : "None"}</div>
        )}
        placeholder="Select values"
      />

      //   </div>
      // </div>
    );
  }
}

export default ItemType;
