import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";
// import { KeywordsSearch } from "../../utils/constants";

export default function ParentStyleNumber() {
  return (
    <MultiDropdownList
      title="Parent Style Number"
      className="form-field"
      componentId="ParentStyleNbr"
      dataField={"ParentStyleNumber.keyword"}
      size={100}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
  );
}
