import React from "react";
import { MultiDropdownList } from "@appbaseio/reactivesearch";

export default function Maker() {
  return (
    // <div className="row p-0 pt-2">
    //   <div className="col-sm-auto p-0">Maker</div>
    //   <div className="col-sm-auto">
    <MultiDropdownList
      title="Maker"
      className="form-field"
      componentId="Maker"
      dataField={"Maker.keyword"}
      size={100}
      renderListItem={(label) => <div>{label !== "NULL" ? label : "None"}</div>}
      showCount={false}
      showSearch={true}
      sortBy="asc"
      placeholder="Select values"
    />
    //   </div>
    // </div>
  );
}
