import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import {
  AppbaseAppUrl,
  JewelrySerialApp,
  JewelryStyleApp,
  AppbaseCredentials,
  SerialDataField,
  StyleDataField,
  KeywordsSearch,
  WRShapeSerial,
  WRShapeStyle,
  KwiatWebProductApp,
  FredWebProductApp,
  KwiatWebVariations,
  FredWebVariations,
} from "../utils/constants";
import { Container } from "react-bootstrap";
import Header from "./Sub Components/Header";
import AllStyle from "./Sub Components/AllStyle";
import WebProduct from "./Sub Components/WebProduct";
import WebVariations from "./Sub Components/WebVariations";
import All_InStockSerials from "./Sub Components/All_InStockSerials";
import LoadingOverlay from "react-loading-overlay";
// import { ToastContainer } from "react-toastify";

export default function Main() {
  const [state, setState] = useState({
    showData: false,
    isLoading: false,
  });

  const toggleLoader = (value) => {
    setState({ ...state, isLoading: value });
  };

  const handleShowData = (value) => {
    setState({ ...state, showData: value });
  };
  return (
    <LoadingOverlay active={state.isLoading} spinner text="Loading...">
      <Container fluid className="p-2">
        <Header handleShowData={handleShowData} />
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <AllStyle
                {...props}
                appName={JewelryStyleApp}
                appUrl={AppbaseAppUrl}
                appCredentials={AppbaseCredentials}
                showData={state.showData}
                handleShowData={handleShowData}
                toggleLoader={toggleLoader}
              />
            )}
          />
          <Route
            exact
            path="/KW-Web-Product"
            render={(props) => (
              <AllStyle
                {...props}
                appName={KwiatWebProductApp}
                appUrl={AppbaseAppUrl}
                appCredentials={AppbaseCredentials}
                showData={state.showData}
                handleShowData={handleShowData}
                toggleLoader={toggleLoader}
              />
            )}
          />
          <Route
            exact
            path="/FL-Web-Product"
            render={(props) => (
              <AllStyle
                {...props}
                appName={FredWebProductApp}
                appUrl={AppbaseAppUrl}
                appCredentials={AppbaseCredentials}
                showData={state.showData}
                handleShowData={handleShowData}
                toggleLoader={toggleLoader}
              />
            )}
          />
          <Route
            exact
            path="/KW-Web-Variations"
            render={(props) => (
              <AllStyle
                {...props}
                appName={KwiatWebVariations}
                appUrl={AppbaseAppUrl}
                appCredentials={AppbaseCredentials}
                showData={state.showData}
                handleShowData={handleShowData}
                toggleLoader={toggleLoader}
              />
            )}
          />
          <Route
            exact
            path="/FL-Web-Variations"
            render={(props) => (
              <AllStyle
                {...props}
                appName={FredWebVariations}
                appUrl={AppbaseAppUrl}
                appCredentials={AppbaseCredentials}
                showData={state.showData}
                handleShowData={handleShowData}
                toggleLoader={toggleLoader}
              />
            )}
          />
          <Route
            exact
            path="/All-In-Stock-Serials"
            component={All_InStockSerials}
          />
        </Switch>
        {/* <Results /> */}
        {/* <ToastContainer hideProgressBar={true} /> */}
      </Container>
    </LoadingOverlay>
  );
}
