import React, { useState, useEffect } from "react";
import { Card, FormLabel, Form, Button } from "react-bootstrap";
import currencyFormatter from "currency-formatter";
import { Row, Col, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import Appbase from "appbase-js";
import Select from "react-select";
import { PriorityDetails } from "../Data/PriorityDetails";
import { WebProductGroup } from "../Data/WebProductGroup";
import { Tags } from "../Data/Tags";
import {
  KwiatWebProductApp,
  FredWebProductApp,
  KwiatWebVariations,
  FredWebVariations,
  AppbaseAppUrl,
  AppbaseCredentials,
} from "../../utils/constants";
import $ from "jquery";
import axios from "axios";
import { basketBaseUrl, ApiKey, ApiBaseUrl } from "../../utils/constants";
import GetAuthToken from "../Components/Authenticate";
import ImageModal from "../Components/ImageModal";
import ReactImageFallback from "react-image-fallback";
import IframeModal from "../Components/IframeModal";

export default function Results(props) {
  const {
    data,
    ShowLoader,
    styleNumberList,

    toggleLoader,
    rawData,
    appName,
    appUrl,
    appCredentials,
    attributes,
  } = props;
  // console.log("data : ", data);
  // console.log("rawData: ", rawData);
  // console.log("styleNumberList : ", styleNumberList);
  // console.log("attributes : ", attributes);

  const [state, setState] = useState({
    data: data,
    styleNumber: "",
    tags: [],
    showImageModal: false,
    zoomImage: "",
    webImage: false,
    url: "",
    editorial: "",
    iframeModal: false,
  });

  const showIframeModal = (url, editorial) => {
    setState({ ...state, iframeModal: true, url: url, editorial: editorial });
  };
  const hideIframeModal = () => {
    setState({ ...state, iframeModal: false, url: "", editorial: "" });
  };

  // console.log("state: ", state);

  const showZoomImage = (res, eventPosition) => {
    function showWebImage(img) {
      var src = "https://cdn4.kwiat.com/source-images/web/original/" + img;
      return src;
    }
    function showimage(image) {
      let img,
        str = "";
      if (image && image != null) {
        let searchimage;
        searchimage = image;
        str = searchimage.split("\\");
        searchimage = str[str.length - 1];
        img = "https://cdn.kwiat.com/source-images/large/" + searchimage;
      } else {
        img = "";
      }
      return img;
    }
    const webImgName = (img) => img.replace(/ /g, "");
    const largeImgName = (img) => {
      var str = img.split("\\");
      return str[str.length - 1];
    };

    var imgArr = [];
    if (eventPosition === "onWebImageIcon") {
      imgArr.push({
        original: showWebImage(res.WebImage1),
        thumbnail: showWebImage(res.WebImage1),
        imgName: webImgName(res.WebImage1),
      });
    } else {
      if (res.LargeImageName) {
        imgArr.push({
          original: showimage(res.LargeImageName),
          thumbnail: showimage(res.LargeImageName),
          imgName: largeImgName(res.LargeImageName),
        });
      }
      for (let i = 1; i < 6; i++) {
        var field = "WebImage" + i;
        if (res[field]) {
          imgArr.push({
            original: showWebImage(res[field]),
            thumbnail: showWebImage(res[field]),
            imgName: webImgName(res[field]),
          });
        }
      }
    }
    setState({ ...state, showImageModal: true, zoomImage: imgArr });
  };
  const hideImageModal = () => {
    setState({ ...state, showImageModal: false });
  };

  const handleTags = (e, res) => {
    let tag = state.tags
      .map((t) =>
        t
          .map((tag) => {
            if (
              tag.description === e.target.selectedOptions[0].text &&
              tag.InventoryDBID === res.InventoryDBID
            ) {
              return tag;
            }
          })
          .filter((tag) => tag !== undefined)
      )
      .filter((t) => t.length !== 0);
    // console.log(" existing tags: ", tag);
    if (tag.length === 0) {
      setState({
        ...state,
        tags: [
          ...state.tags,
          [
            {
              InventoryDBID: res.InventoryDBID,
              description: e.target.selectedOptions[0].text,
              value: e.target.value,
            },
          ],
        ],
        styleNumber: res.StyleNumber,
      });
    } else {
      window.alert("Tag already selected.");
    }
    // console.log("Updated tags: ", state.tags);
  };

  const handleRemoveTag = (name, res) => {
    // console.log("name: ", name);
    setState({
      ...state,
      tags: state.tags.map((t) =>
        t
          .map((tag) => {
            // console.log("tag: ", tag);
            if (
              tag.description !== name &&
              (tag.InventoryDBID !== res.InventoryDBID ||
                tag.InventoryDBID === res.InventoryDBID)
            ) {
              return tag;
            }
          })
          .filter((tag) => tag !== undefined)
      ),
    });
    // console.log("removed tags: ", state.tags);
  };

  // const savedetails = (event) => {
  //   console.log(
  //     "Kwiat: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Kwiat")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "FL: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".FL")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Ecom: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Ecom")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Center Variation: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Center")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Serail as Individual: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Ind")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Serail as Variations: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Var")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Shopable Center: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Shopable")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Hide Price: ",
  //     event.target.parentNode.parentNode.children[1].querySelector(".Hide")
  //       .children[0].checked
  //   );
  //   console.log(
  //     "Priority: ",
  //     event.target.parentNode.parentNode.children[0].querySelector(".Priority")
  //       .value
  //   );
  //   console.log(
  //     "WebProductGroup: ",

  //     event.target.parentNode.parentNode.children[2].querySelector(
  //       'option[label="' +
  //         event.target.parentNode.parentNode.children[2].querySelector(
  //           ".WebProductGroup"
  //         ).value +
  //         '"]'
  //     ).value
  //   );
  // };

  const getValues = (parent, name) => {
    // const parent = event.target.parentNode.parentNode;
    if (
      name === ".Kwiat" ||
      name === ".FL" ||
      name === ".Var" ||
      name === ".Ind" ||
      name === ".Shopable" ||
      name === ".Ecom" ||
      name === ".Hide" ||
      name === ".Center"
    ) {
      let value = parent.querySelector(name).children[0].checked ? "1" : "0";
      return value;
    } else if (name === ".Master") {
      let value = parent.children[3].querySelector(name).children[0].checked
        ? "1"
        : "0";
      return value;
    } else if (name === ".Priority") {
      let value = parent.children[3].querySelector(name).value;
      return value;
    } else if (name === ".WebProductGroup") {
      let value = parent.children[3].querySelector(name).value;
      // let label = parent.children[3].querySelector(name)[
      //   parent.children[3].querySelector(name).selectedIndex
      // ].innerHTML;
      let label = WebProductGroup.filter((w) => w.value === value)[0].label;
      // console.log("label: ", label);
      return { label, value };
    } else if (name === ".StyleGroup") {
      let value = parent.children[3].querySelector(name).value;
      // let label = parent.children[3].querySelector(name)[
      //   parent.children[3].querySelector(name).selectedIndex
      // ].innerHTML;
      // let label = attributes.filter((w) => value === value)[0].label;
      // console.log("label: ", label);
      return { value };
    } else if (name === ".ParentStyleNumber") {
      let value = parent.children[3].querySelector(name).value;
      return value;
    } else if (name === ".Tags") {
      // console.log("tags: ", state.tags);
      let tagsValue =
        state.tags.flat(Infinity).length !== 0
          ? state.tags.map((t) => t[0].value)
          : "";
      let value = tagsValue && tagsValue.join(",");
      let tagsLabel =
        state.tags.flat(Infinity).length !== 0
          ? state.tags.map((t) => t[0].description)
          : "";
      let label = tagsLabel && tagsLabel.join(",");
      // console.log("tags:", { label, value });
      return { label, value };
    }
  };

  const appbaseUpdate = async (
    res,
    {
      ShowonKwiatcomValue,
      ShowSerialsasVariationsValue,
      ShowSerialsasIndividualProductsValue,
      ShowonFLcomValue,
      ShoppableCenterStoneValue,
      EcomEnabledValue,
      HidePriceValue,
      CenterVariationsValue,
      MasterParentStyleValue,
      ParentStyleNumberValue,
      PriorityValue,
      WebProductGroupValue,
      StyleGroup,
      TagAttributesValue,
    }
  ) => {
    let _id;
    // console.log("appName: ", appName);
    // console.log("appUrl: ", appUrl);
    // console.log("appCredentials: ", appCredentials);
    // console.log("res: ", res);
    var payload = {
      // InventoryID: { value: fields.StyleNumber },
      // Serial: {
      //   value: fields.LotserialNumber ? fields.LotserialNumber : "",
      // },
      ShowonKwiatcom: {
        value: ShowonKwiatcomValue,
      },
      ShowSerialsasVariations: {
        value: ShowSerialsasVariationsValue,
      },
      ShowSerialsasIndividualProducts: {
        value: ShowSerialsasIndividualProductsValue,
      },
      ShowonFLcom: {
        value: ShowonFLcomValue,
      },
      ShoppableCenterStone: {
        value: ShoppableCenterStoneValue,
      },
      EcomEnabled: {
        value: EcomEnabledValue,
      },
      HidePrice: {
        value: HidePriceValue,
      },
      CenterVariations: {
        value: CenterVariationsValue,
      },
      MasterParentStyle: {
        value: MasterParentStyleValue,
      },
      ParentStyleNumber: {
        value: ParentStyleNumberValue,
      },

      Priority: { value: PriorityValue },
      WebProductGroup: {
        value: WebProductGroupValue,
      },
      StyleGroup: { value: StyleGroup },
      TagAttributes: { value: TagAttributesValue },
    };
    // console.log("appbase payload :", payload);
    const appbaseRef = Appbase({
      app: appName,
      url: appUrl,
      credentials: appCredentials,
    });

    await appbaseRef
      .search({
        body: {
          query: {
            match: {
              InventoryDBID: {
                query: res.InventoryDBID,
              },
            },
          },
          _source: ["InventoryDBID"],
          size: 10000,
        },
      })
      .then(function(res) {
        // console.log("query result: ", res.hits.hits);
        _id = res.hits.hits[0]._id;
        // console.log("_id: ", _id);
        // let data = res.hits.hits.map((res) => res._source.StyleNumber);
        // // console.log("data: ", data);
        // // console.log("No of products: ", data.length);
        // setState({
        //   ...state,
        //   styleNumberList: data.map((d) => ({ label: d, value: d })),
        // });
      })
      .catch(function(err) {
        // console.log("search error: ", err);
      });

    await appbaseRef
      .update({
        type: "_doc",
        id: _id,
        body: {
          doc: {
            // ShoppableSettings: ShoppableCenterStoneValue,
            // ShowOnKwiatcom
            // ShowonFLcom
            // EcomEnabled
            // ShowSerailAsVar
            // CenterVariations
            // ShowSerialAsIndProduct
            // ShoppableSettings
            // HidePrice
            // MasterParentStyle
            ShowOnFLcom: ShowonFLcomValue,
            ShowOnKwiatcom: ShowonKwiatcomValue,
            ShowSerailAsVar: ShowSerialsasVariationsValue,
            ShowSerialAsIndProduct: ShowSerialsasIndividualProductsValue,
            EcomEnabled: EcomEnabledValue,
            CenterVariations: CenterVariationsValue,
            ShoppableSettings: ShoppableCenterStoneValue,
            HidePrice: HidePriceValue,
            MasterParentStyle: MasterParentStyleValue,
            ProductGroupDescr: WebProductGroupValue.label,
            ProductGroupValueId: WebProductGroupValue.value,
            TagAttributeDescr: TagAttributesValue.label,
            TagAttributeValues: TagAttributesValue.value,
            ParentStyleNumber: ParentStyleNumberValue,
            Priority: PriorityValue,
            StyleGroup: StyleGroup.value,
          },
        },
      })
      .then(function(res) {
        console.log("Successfully Updated.");
        // console.log("successfully updated: ", res);
      })
      .catch(function(err) {
        console.log("update document error: ", err);
      });
    let updatedData = state.data;
    // console.log("Updated Data: ", updatedData);
    let index = updatedData.indexOf(res);
    // console.log("index: ", index);
    updatedData[index] = {
      ...updatedData[index],
      ShowOnFLcom: ShowonFLcomValue,
      ShowOnKwiatcom: ShowonKwiatcomValue,
      ShowSerailAsVar: ShowSerialsasVariationsValue,
      ShowSerialAsIndProduct: ShowSerialsasIndividualProductsValue,
      EcomEnabled: EcomEnabledValue,
      CenterVariations: CenterVariationsValue,
      ShoppableSettings: ShoppableCenterStoneValue,
      HidePrice: HidePriceValue,
      MasterParentStyle: MasterParentStyleValue,
      ProductGroupDescr: WebProductGroupValue.label,
      ProductGroupValueId: WebProductGroupValue.value,
      TagAttributeDescr: TagAttributesValue.label,
      TagAttributeValues: TagAttributesValue.value,
      ParentStyleNumber: ParentStyleNumberValue,
      Priority: PriorityValue,
      StyleGroup: StyleGroup.value,
    };
    // console.log("updated data", [index], " :", updatedData[index]);
    // console.log("Updated data: ", updatedData);

    setState({ ...state, data: updatedData });
    // console.log("state: ", state);
  };

  const SaveDetails = async (event, fields) => {
    toggleLoader(true);
    const parent = event.target.parentNode.parentNode.parentNode;
    let ShowonKwiatcomValue = getValues(parent, ".Kwiat");
    let ShowSerialsasVariationsValue = getValues(parent, ".Var");
    let ShowSerialsasIndividualProductsValue = getValues(parent, ".Ind");
    let ShowonFLcomValue = getValues(parent, ".FL");
    let ShoppableCenterStoneValue = getValues(parent, ".Shopable");
    let EcomEnabledValue = getValues(parent, ".Ecom");
    let HidePriceValue = getValues(parent, ".Hide");
    let CenterVariationsValue = getValues(parent, ".Center");
    let MasterParentStyleValue = getValues(parent, ".Master");
    let ParentStyleNumberValue = getValues(parent, ".ParentStyleNumber");
    let PriorityValue = getValues(parent, ".Priority");
    let WebProductGroupValue = getValues(parent, ".WebProductGroup");
    let StyleGroup = getValues(parent, ".StyleGroup");
    let TagAttributesValue = getValues(parent, ".Tags");
    // console.log("fields : ", fields);

    appbaseUpdate(fields, {
      ShowonKwiatcomValue,
      ShowSerialsasVariationsValue,
      ShowSerialsasIndividualProductsValue,
      ShowonFLcomValue,
      ShoppableCenterStoneValue,
      EcomEnabledValue,
      HidePriceValue,
      CenterVariationsValue,
      MasterParentStyleValue,
      ParentStyleNumberValue,
      PriorityValue,
      WebProductGroupValue,
      StyleGroup,
      TagAttributesValue,
    });

    var token = await GetAuthToken();
    // console.log("token : ", token);
    // console.log("state: ", state);
    var payload = {
      data: {
        defaults: {
          baseURL: basketBaseUrl,
          token: token.access_token,
        },
        inputs: {
          InventoryID: { value: fields.StyleNumber },
          Serial: {
            value: fields.LotserialNumber ? fields.LotserialNumber : "",
          },
          ShowonKwiatcom: {
            value: ShowonKwiatcomValue,
          },
          ShowSerialsasVariations: {
            value: ShowSerialsasVariationsValue,
          },
          ShowSerialsasIndividualProducts: {
            value: ShowSerialsasIndividualProductsValue,
          },
          ShowonFLcom: {
            value: ShowonFLcomValue,
          },
          ShoppableCenterStone: {
            value: ShoppableCenterStoneValue,
          },
          EcomEnabled: {
            value: EcomEnabledValue,
          },
          HidePrice: {
            value: HidePriceValue,
          },
          CenterVariations: {
            value: CenterVariationsValue,
          },
          MasterParentStyle: {
            value: MasterParentStyleValue,
          },
          ParentStyleNumber: {
            value: ParentStyleNumberValue,
          },

          Priority: { value: PriorityValue },
          WebProductGroup: {
            value: WebProductGroupValue.value,
          },
          StyleGroup: { value: StyleGroup.value },
          TagAttributes: { value: TagAttributesValue.value },
          // ShowonKwiatcom: {
          //   value: getValues(parent, ".Kwiat"),
          // },
          // ShowSerialsasVariations: {
          //   value: getValues(parent, ".Var"),
          // },
          // ShowSerialsasIndividualProducts: {
          //   value: getValues(parent, ".Ind"),
          // },
          // ShowonFLcom: {
          //   value: getValues(parent, ".FL"),
          // },
          // ShoppableCenterStone: {
          //   value: getValues(parent, ".Shopable"),
          // },
          // EcomEnabled: {
          //   value: getValues(parent, ".Ecom"),
          // },
          // HidePrice: {
          //   value: getValues(parent, ".Hide"),
          // },
          // CenterVariations: {
          //   value: getValues(parent, ".Center"),
          // },
          // MasterParentStyle: {
          //   value: getValues(parent, ".Master"),
          // },
          // ParentStyleNumber: {
          //   value: getValues(parent, ".ParentStyleNumber"),
          // },

          // Priority: { value: getValues(parent, ".Priority") },
          // WebProductGroup: {
          //   value: getValues(parent, ".WebProductGroup"),
          // },
          // TagAttributes: { value: getValues(parent, ".Tags") },
        },
      },
    };
    // console.log("payload :", payload);
    var response = await axios.post(
      "https://snjlwwmeo3.execute-api.us-east-1.amazonaws.com/test/data",
      payload,
      {
        headers: {
          "x-api-key": "ddf8P1OcJ212f6txhZMksavqGYXurdqs8IW7msxy",
        },
      }
    );
    if (response.data && response.data.statusCode === 200) {
      toggleLoader(false);
      // console.log("response-data : ", response.data);
      // window.alert("Data Saved.");
      // setState({ styleNumber: "", tags: [] });
      // console.log("state: ", state);
      toast.success("Data Saved !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
    } else {
      toggleLoader(false);
      // console.log("response-data : ", response.data);
      // window.alert("Data Not Saved");
      // setState({ styleNumber: "", tags: [] });
      console.log("state: ", state);
      toast.error("Data Not Saved ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
        pauseOnHover: false,
      });
    }
  };

  const showimage = (image) => {
    let img,
      str = "";
    if (image && image != null) {
      let searchimage;
      searchimage = image;
      str = searchimage.split("\\");
      searchimage = str[str.length - 1].replace(/icon/g, "search");
      searchimage = searchimage.replace(/Icon/g, "search");
      img = "https://cdn.kwiat.com/source-images/search/" + searchimage;
    } else {
      img = "";
      str = "";
    }
    return img;
  };

  const showWebImage = (img) => {
    var src =
      "https://cdn4.kwiat.com/source-images/web/altviews/" +
      img.replace(/\.[^.]*$/, "") +
      "-altview@2x.jpg";
    return src;
  };

  const thumbnailImage = (res) => {
    var src = false;
    var thumbnailImageType;
    if (res.WebImage1) {
      // console.log("Inside WebImage1 if");
      src = showWebImage(res.WebImage1);
      thumbnailImageType = "webImage";
    } else if (
      res.SerialVideoLink &&
      res.SerialVideoLink.includes("/v360/") &&
      res.IconImageName &&
      res.IconImageName.includes("still")
    ) {
      var productId = res.SerialVideoLink.match("imaged/(.*)/")[1];
      src = `https://cdn.kwiat.com/kwiat/v360/imaged/${productId}/still.jpg`;
      thumbnailImageType = "v360";
    } else if (res.IconImageName) {
      src = showimage(res.IconImageName);
      thumbnailImageType = "iconImage";
    }
    // else {
    //   src =
    //     "https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg";
    // }
    return { src, thumbnailImageType };
  };

  const Index = (n) => {
    return n > 9 ? "" + n : "0" + n;
  };

  useEffect(
    () => {
      setState({
        ...state,
        data: data,
        tags: data
          .map((res) => {
            if (res.TagAttributeDescr && res.TagAttributeValues) {
              let descriptions = res.TagAttributeDescr.split(",");
              let values = res.TagAttributeValues.split(",");
              let tags = descriptions.map((des, index) => ({
                InventoryDBID: res.InventoryDBID,
                description: des,
                value: values[index],
              }));
              // tags = JSON.stringify(tags).replace(/]/g, "").replace(/[[]/g,"");
              // console.log("tags", tags);

              // tags.forEach((tag)=>)

              return tags;
            }
          })
          .filter((t) => t !== undefined),
      });
    },
    [data]
  );
  // console.log("state.data: ", state.data);
  // console.log("tags: ", state.tags);

  return (
    <>
      <Row className="Result-Row">
        {state.data.map((res, index) => (
          <Card key={state.data.indexOf(res)}>
            <Row>
              <Col sm="auto" className="item_no">
                <span>{Index(index + 1)}</span>
              </Col>
              <Col sm="1" className="item_image_container">
                {res.StyleVideoLink || res.EditorialVideo || res.WebImage1 ? (
                  <Row className="item_image_links">
                    {res.StyleVideoLink ? (
                      <img
                        onClick={() =>
                          setState({
                            ...state,
                            iframeModal: true,
                            url: res.StyleVideoLink,
                            editorial: "",
                          })
                        }
                        className="web-image-icon"
                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                        height="14px"
                        width="20px"
                        alt="product"
                      />
                    ) : (
                      ""
                    )}
                    {res.EditorialVideo ? (
                      <img
                        onClick={() =>
                          setState({
                            ...state,
                            iframeModal: true,
                            url: "",
                            editorial: res.EditorialVideo,
                            // editorial: "35d7c305934139786d1e06833d4a5aaa",
                          })
                        }
                        className="web-image-icon"
                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Video-Icon-Stock-Black.svg"
                        height="20px"
                        width="26px"
                        alt="product"
                      />
                    ) : (
                      ""
                    )}
                    {res.WebImage1 && (
                      <img
                        className="web-image-icon"
                        src="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/White-Background-Available.svg"
                        alt="web Icon"
                        height="23px"
                        width="30px"
                        onClick={() => showZoomImage(res, "onWebImageIcon")}
                      />
                    )}
                  </Row>
                ) : (
                  ``
                )}
                <Row
                  className="item_image"
                  style={
                    res.StyleVideoLink || res.EditorialVideo || res.WebImage1
                      ? { height: "90%" }
                      : { height: "100%" }
                  }
                >
                  {thumbnailImage(res).src ? (
                    <Image
                      className="item_image_icon"
                      src={thumbnailImage(res).src}
                      onClick={() =>
                        thumbnailImage(res).src
                          ? showZoomImage(res, "onImage")
                          : ""
                      }
                      rounded
                      style={
                        thumbnailImage(res).src ? { cursor: "pointer" } : {}
                      }
                    />
                  ) : (
                    <ReactImageFallback
                      fallbackImage="https://cdn.kwiat.com/apps/kwiat-elastic-search/icons/Missing-Images-Final-100x75px-01.svg"
                      // initialImage="loader.gif"
                      alt=""
                      className="product-image-icon"
                    />
                  )}
                </Row>
              </Col>
              <Col sm="auto" className="item_short_details">
                {res.Brand && (
                  <label>
                    Brand: <span>{res.Brand}</span>
                  </label>
                )}
                {res.InventoryID && (
                  <label>
                    Inventory ID: <span>{res.InventoryID}</span>
                  </label>
                )}
                {res.Name && (
                  <label>
                    Name: <span>{res.Name}</span>
                  </label>
                )}
                {res.HasWebImage && (
                  <label>
                    Has Web Image:{" "}
                    <span>{res.HasWebImage === "0" ? "FALSE" : "TRUE"}</span>
                  </label>
                )}

                {res.WebDataEntered && (
                  <label>
                    Web Data Entered: <span>{res.WebDataEntered}</span>
                  </label>
                )}

                {res.RetailPrice && (
                  <label>
                    Retail Price:{" "}
                    <span>
                      {currencyFormatter.format(`${res.RetailPrice}`, {
                        code: "USD",
                        precision: 0,
                      }) || "$0"}
                    </span>
                  </label>
                )}
                {res.StockLevels !== null && (
                  <label>
                    Stock Levels: <span>{res.StockLevels}</span>
                  </label>
                )}
              </Col>
              <Col sm="auto" className="web_control">
                <Row className="web_control_header">
                  <span>Web Controls</span>
                </Row>
                <Row className="sort_priority">
                  <Col sm="8" className="p-0 m-0 final_sort">
                    <FormLabel className="">
                      <span>Final Sort:</span> 201321232
                    </FormLabel>
                  </Col>
                  <Col sm="4" className="pr-1 p-0 m-0 priority">
                    <label>Priority</label>
                    <select className="Priority" defaultValue={res.Priority}>
                      {PriorityDetails.map((pd, index) => (
                        <option
                          key={index}
                          value={pd.value}
                          // selected={res.Priority === pd.label ? true : false}
                        >
                          {pd.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row className="web_control_checkboxes">
                  <Col sm="5" className="p-0 m-0">
                    <Form.Group controlId="formBasicCheckbox" className=" m-0">
                      <Form.Check
                        className="Kwiat"
                        type="checkbox"
                        label="Show on Kwiat.com"
                        defaultChecked={
                          res.ShowOnKwiatcom === "1" ? true : false
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox" className=" m-0">
                      <Form.Check
                        className="FL"
                        type="checkbox"
                        label="Show on FL.com"
                        defaultChecked={res.ShowOnFLcom === "1" ? true : false}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox" className=" m-0">
                      <Form.Check
                        className="Ecom"
                        type="checkbox"
                        label="Ecom Enabled"
                        defaultChecked={res.EcomEnabled === "1" ? true : false}
                      />
                    </Form.Group>
                    {appName === KwiatWebVariations ||
                    appName === FredWebVariations ? (
                      ""
                    ) : (
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className=" m-0"
                      >
                        <Form.Check
                          className="Var"
                          type="checkbox"
                          label="Show Serials as Variations"
                          defaultChecked={
                            res.ShowSerailAsVar === "1" ? true : false
                          }
                        />
                      </Form.Group>
                    )}
                  </Col>
                  <Col sm="6" className="p-0 m-0">
                    {appName === KwiatWebVariations ||
                    appName === FredWebVariations ? (
                      ""
                    ) : (
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className=" m-0"
                      >
                        <Form.Check
                          className="Center"
                          type="checkbox"
                          label="Center Variations"
                          defaultChecked={
                            res.CenterVariations === "1" ? true : false
                          }
                        />
                      </Form.Group>
                    )}
                    {appName === KwiatWebVariations ||
                    appName === FredWebVariations ? (
                      ""
                    ) : (
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className=" m-0"
                      >
                        <Form.Check
                          className="Ind"
                          type="checkbox"
                          label="Show Serials as Individual Products"
                          defaultChecked={
                            res.ShowSerialAsIndProduct === "1" ? true : false
                          }
                        />
                      </Form.Group>
                    )}
                    {appName === KwiatWebVariations ||
                    appName === FredWebVariations ? (
                      ""
                    ) : (
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className=" m-0"
                      >
                        <Form.Check
                          className="Shopable"
                          type="checkbox"
                          label="Shoppable Center"
                          defaultChecked={
                            res.ShoppableSettings === "1" ? true : false
                          }
                        />
                      </Form.Group>
                    )}
                    <Form.Group controlId="formBasicCheckbox" className=" m-0">
                      <Form.Check
                        className="Hide"
                        type="checkbox"
                        label="Hide Price"
                        defaultChecked={res.HidePrice === "1" ? true : false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="web_product_group">
                  <span>Web Product Group</span>
                  <select
                    className="WebProductGroup"
                    defaultValue={res.ProductGroupValueId}
                  >
                    {WebProductGroup.map((wpg, index) => (
                      <option
                        key={index}
                        value={wpg.value}
                        // selected={
                        //   res.ProductGroupDescr === wpg.label ? true : false
                        // }
                      >
                        {wpg.label}
                      </option>
                    ))}
                  </select>
                </Row>
                <Row className="style_group">
                  <span>Style Group</span>
                  <select className="StyleGroup" defaultValue={res.StyleGroup}>
                    {attributes.map((style, index) => (
                      <option
                        key={index}
                        value={style}
                        // selected={
                        //   res.ProductGroupDescr === wpg.label ? true : false
                        // }
                      >
                        {style}
                      </option>
                    ))}
                  </select>
                </Row>
                <Row className="master_parent_style">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className="pr-1 m-0"
                  >
                    <Form.Check
                      className="Master"
                      type="checkbox"
                      label="Master Parent Style"
                      defaultChecked={
                        res.MasterParentStyle === "1" ? true : false
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="parent_style_number">
                  <span>Parent Style Number</span>
                  <select
                    className="ParentStyleNumber"
                    defaultValue={res.ParentStyleNumber}
                  >
                    <option key="" />
                    {styleNumberList && styleNumberList.map((PSN, index) => (
                      <option
                        key={index}
                        value={PSN.value}
                        // selected={
                        //   res.ParentStyleNbr === PSN.label ? true : false
                        // }
                      >
                        {PSN.label}
                      </option>
                    ))}
                  </select>
                </Row>
                <Row className="web_control_save">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => SaveDetails(e, res)}
                    // onClick={(e) => appbaseUpdate(res)}
                  >
                    Save
                  </Button>
                </Row>
              </Col>
              <Col sm="auto" className="tags">
                <Row className="tag_header">
                  <span>Tags</span>
                </Row>

                <Row className="select_tags">
                  <select
                    className="ParentStyleNumber"
                    onChange={(e) => handleTags(e, res)}
                    multiple
                  >
                    {Tags.map((t, index) => (
                      <option
                        key={index}
                        value={t.value}
                        // selected={res.ParentStyleNbr === PSN.label ? true : false}
                      >
                        {t.label}
                      </option>
                    ))}
                  </select>
                </Row>
                <Row className="tags_selected">
                  {/* {res.StyleNumber === state.styleNumber */}
                  {state.tags &&
                    state.tags.map((v) =>
                      v.map((tag) => {
                        // console.log("tags :", state.tags);
                        // console.log("tag: ", tag);
                        if (tag.InventoryDBID === res.InventoryDBID)
                          return (
                            <FormLabel key={tag.value}>
                              {tag.description}
                              <i
                                className="fa fa-times"
                                onClick={() =>
                                  handleRemoveTag(tag.description, res)
                                }
                              />
                            </FormLabel>
                          );
                      })
                    )}
                  {/* {res.TagAttributeDescr &&
                    res.TagAttributeDescr.split(",").map((desc, i) => (
                      <FormLabel key={res.TagAttributeValues.split(",")[i]}>
                        {desc}
                        <i
                          className="fa fa-times"
                          onClick={() => handleRemoveTag(desc, res)}
                        />
                      </FormLabel>
                    ))} */}
                </Row>
                <Row className="save_tags">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={(e) => SaveDetails(e, res)}
                  >
                    Save
                  </Button>
                </Row>
              </Col>
              <Col sm="auto" className="item_details">
                {/* <Row>
                  <Col sm="auto"> */}

                <Row className="details_header">
                  <span>Details</span>
                </Row>
                <div className="details">
                  {res.StyleNumber && (
                    <Row>
                      <span>Inventory ID:</span> <span>{res.StyleNumber}</span>
                    </Row>
                  )}

                  {res.Metal && (
                    <Row>
                      <span>Metal: </span>
                      <span>{res.Metal}</span>
                    </Row>
                  )}

                  {res.ItemType && (
                    <Row>
                      <span> Item: </span>
                      <span>{res.ItemType}</span>
                    </Row>
                  )}

                  {res.ItemSubtype && (
                    <Row>
                      <span>Item SubType: </span>
                      <span>{res.ItemSubtype}</span>
                    </Row>
                  )}

                  {res.Maker && (
                    <Row>
                      <span> Maker: </span>
                      <span>{res.Maker}</span>
                    </Row>
                  )}

                  {res.Period && (
                    <Row>
                      <span>Period: </span>
                      <span>{res.Period}</span>
                    </Row>
                  )}

                  {res.ItemClass && (
                    <Row>
                      <span>Item Class: </span>
                      <span>{res.ItemClass}</span>
                    </Row>
                  )}

                  {res.StyleStatus && (
                    <Row>
                      <span>Style Status: </span>
                      <span>{res.StyleStatus}</span>
                    </Row>
                  )}

                  {res.Collection && (
                    <Row>
                      <span> Collection: </span>
                      <span>{res.Collection}</span>
                    </Row>
                  )}

                  {res.SubCollection && (
                    <Row>
                      <span> Sub Collection: </span>
                      <span>{res.SubCollection}</span>
                    </Row>
                  )}

                  {res.InventoryCategory && (
                    <Row>
                      <span> Inventory: </span>
                      <span>{res.InventoryCategory}</span>
                    </Row>
                  )}

                  {res.PartwayEternity && (
                    <Row>
                      <span> WR Partway/Eternity: </span>
                      <span>{res.PartwayEternity}</span>
                    </Row>
                  )}

                  {res.WRStoneShape && (
                    <Row>
                      <span>Wr Stone Shape: </span>
                      <span>{res.WRStoneShape}</span>
                    </Row>
                  )}

                  {res.SettingType && (
                    <Row>
                      <span>WR Setting Type: </span>
                      <span>{res.SettingType}</span>
                    </Row>
                  )}

                  {res.Availability && (
                    <Row>
                      <span>Availability: </span>
                      <span>{res.Availability}</span>
                    </Row>
                  )}

                  {res.BridalParentGroup && (
                    <Row>
                      <span> BridalParentGroup: </span>
                      <span>{res.BridalParentGroup}</span>
                    </Row>
                  )}

                  {res.CenterShape && (
                    <Row>
                      <span>ER Shape: </span>
                      <span>{res.CenterShape}</span>
                    </Row>
                  )}

                  {res.CenterSettingType && (
                    <Row>
                      <span>ER Setting: </span>
                      <span>{res.CenterSettingType}</span>
                    </Row>
                  )}

                  {res.CenterGemstoneType && (
                    <Row>
                      <span>ER GemStone: </span>
                      <span>{res.CenterGemstoneType}</span>
                    </Row>
                  )}
                </div>
                {/* </Col>
                </Row> */}
              </Col>
            </Row>
          </Card>
        ))}
      </Row>
      {state.showImageModal && state.zoomImage !== null && (
        <ImageModal
          show={state.showImageModal}
          callback={hideImageModal}
          image={state.zoomImage}
          webImage={state.webImage}
        />
      )}
      <IframeModal
        show={state.iframeModal}
        url={state.url}
        editorial={state.editorial}
        hide={hideIframeModal}
      />
    </>
  );
}
