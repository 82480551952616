import "./App.css";
import Main from "./components/Main";
import { HashRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import ReactSelect from "./Prac/ReactSelect";

function App() {
  // document.addEventListener("mouseup", function(e) {
  //   var container = document.querySelector("datalist");
  //   console.log("container: ", container);
  //   if(container){
  //   if (!container.contains(e.target)) {
  //     container.style.display = "none";
  //   }
  // }
  // });
  return (
    <div className="App">
      <Router>
        <Main />
      </Router>
      <ToastContainer hideProgressBar={true} />
      {/* <ReactSelect /> */}
    </div>
  );
}

export default App;
